<template>
  <div>
    <TowerAds />
    <main id="adnm-content" class="pt-70 lg-pt-90">
      <Aptoma />
    </main>
  </div>
</template>

<script setup lang="ts">
// Force native false for static pages.
useState('native').value = false;

/**
 * Inject metatags (OG, Schema.org)
 */
const { frontpageMeta } = useAllerMetaData();
frontpageMeta('BILLED-BLADET har alt om de kongelige, de kendte og det bedste fra tv. Få seneste nyt og se de flotte billeder.');

onMounted(() => {
  const { GTMPush } = useAllerGTM();
  GTMPush({
    pageTitle: 'BILLED-BLADET',
    pageType: 'frontpage',
  });
});
</script>
